import React, { useEffect, useState } from "react";
import { BASE_URL, LOCALHOST_BASE_URL } from "../../src/utils/DataApi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
axios.defaults.withCredentials = true;
import Lottie from "react-lottie";
import { GET_LOGIN_DATA, GET_LANGUAGE } from "../store/actions";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import animation from "../assets/pre_loader.json";
import App from "App";

const AppLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { Authenticator_status } = useSelector((state) => state.loader);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const { language } = useSelector((state) => state.AllLanguage);
  const token = localStorage.getItem("token");
  const loginControl = async () => {
    if (token != null) {
      let url = BASE_URL;
      if (window.location.hostname === "localhost") {
        url = LOCALHOST_BASE_URL;
      } else {
        url = BASE_URL;
      }

      await axios
        .get(`${url}/adminuser/authenticate`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((res) => {
          dispatch({
            type: GET_LOGIN_DATA,
            payload: res.data.user,
          });
          setisAuthenticated(true);
          if (window.location.pathname == "/blocked") {
            navigate("/blocked", { replace: true });
          } else if (window.location.pathname == "/focus/auth/app") {
            // navigate("/focus/auth/app", { replace: true });
          }
          else if (isUnRestrictedRoute(window.location.pathname)) {
            navigate("/client");
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            err?.response?.status === 401 ||
            err?.response?.data?.message == "Unauthorized"
          ) {
            UnAuth();
          } else if (err?.message) {
            toast.error(err?.message);
          } else {
            toast.error(err);
          }
        });
    } else {
      UnAuth();
    }
  };

  useEffect(() => {
    localStorage.setItem(
      "i18nextLng",
      localStorage.getItem("i18nextLng") || getcurrentlanguage()
    );

    loginControl();
  }, []);

  function getcurrentlanguage() {
    const str = navigator.language;
    console.log(str, "language");

    if (str.includes("-")) {
      return str.split("-")[0];
    } else {
      return str;
    }
  }

  // useEffect(() => {
  //   setisAuthenticated(Authenticator_status);
  // }, [Authenticator_status]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function UnAuth() {
    let pathname = window.location.pathname;

    if (pathname === "/login") {
      navigate("/login");
    } else if (pathname === "/register") {
      navigate("/register");
    }
     else if (pathname === "/register/company") {
      navigate("/register/company");
    }
    else if (pathname === "/forgotpassword") {
      navigate("/forgotpassword");
    } else if (pathname === "/resetpassword") {
      navigate("/resetpassword");
    } else if (pathname === "/blocked") {
      navigate("/blocked", { replace: true });
    } 
    else if (pathname === "/focus/auth/app") {

      // navigate("/focus/auth/app", { replace: true });
    } else {
      navigate("/login");
    }
  }
  const isUnRestrictedRoute = (pathname) => {
    return (
      pathname === "/login" ||
      pathname === "/register" ||
      pathname === "/register/company" ||
      pathname === "/forgotpassword" ||
      pathname === "/resetpassword" ||
      pathname === "/blocked" ||
      pathname === "/focus/auth/app"
    );
  };
  return isUnRestrictedRoute(window.location.pathname) ? (
    <App />
  ) : (
    <>
      {isAuthenticated ? (
        <App />
      ) : (
        //Animation For Loading
        <div className="relative">
          <div className="flex items-center justify-center h-[100vh]">
            <Lottie options={defaultOptions} height={80} width={80} />
          </div>
        </div>
      )}
    </>
  );
};
export default AppLayout;
