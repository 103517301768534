import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Popover,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
// project imports
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
// assets
import { IconMenu2 } from "@tabler/icons";
import { GrCircleInformation } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import UsaFlag from "../../../assets/images/icons/usa-flag.svg";
import SpanishFlag from "../../../assets/images/icons/spain-flag.svg";
import Portugueseflag from "../../../assets/images/icons/Portuguese.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// ===import { FaBeer } from 'react-icons/fa';===========================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, width }) => {
  const theme = useTheme();
  const [info, setInfo] = useState(null);
  const isopen = Boolean(info);
  const googleDivRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { login_Session } = useSelector((state) => state.auth);

  const [selectlang, setSelectlang] = useState(
    (localStorage.getItem("i18nextLng") || "").split("-")[0]
  );
  const navigate = useNavigate();

  const redirectToClient = () => {
    if (!login_Session?.isCompanyadmin && !login_Session?.isSuperadmin) {
      if (navigate) {
        navigate("/client");
      }
    } else {
      navigate("/super-admin");
    }
  };

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       // layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL
  //     },
  //     "google_translate_element"
  //   );
  //   const googleDiv = googleDivRef.current;
  //   if (googleDiv) {
  //     const googleDivChild = googleDiv.querySelector('.skiptranslate div');
  //     if (googleDivChild) {
  //       googleDivChild.nextElementSibling.remove();
  //     }

  //     Array.from(googleDiv.childNodes).forEach((child) => {
  //       if (child.nodeType === 3 && child.nodeValue.trim() !== '') {
  //         googleDiv.removeChild(child);
  //       }
  //     });
  //   }
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  useEffect(() => {
    const localLang = (localStorage.getItem("i18nextLng") || "").split("-")[0];
    if (localLang) {
      setSelectlang(localLang);
    }
  }, [selectlang]);

  const changeLanguage = (e) => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
    setSelectlang(lng);
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          className="w-1/2"
          sx={{
            display: { xs: "none", md: "block" },
            flexGrow: 1,
            cursor: "pointer",
          }}
          onClick={redirectToClient}
        >
          <LogoSection width={"75%"} />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}

      <Box className="flex items-center gap-4">
        {/* <div id="google_translate_element" className="h-[30px]" ref={googleDivRef}></div> */}
        <Select
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
          value={selectlang}
          onChange={changeLanguage}
        >
          <MenuItem value="en">
            <div className="flex items-center gap-2 justify-between w-full">
              <span>English</span>
              <img
                src={UsaFlag}
                className="w-[30px] h-[30px] object-cover"
                alt="English"
              />
            </div>
          </MenuItem>
          <MenuItem value="es">
            <div className="flex items-center gap-2 justify-between w-full">
              <span>Spanish</span>
              <img
                src={SpanishFlag}
                className="w-[30px] h-[30px] object-cover"
                alt="Spanish"
              />
            </div>
          </MenuItem>
          <MenuItem value="pt">
            <div className="flex items-center gap-2 justify-between w-full">
              <span>Portuguese</span>
              <img
                src={Portugueseflag}
                className="w-[30px] h-[30px] object-cover"
                alt="Spanish"
              />
            </div>
          </MenuItem>
        </Select>

        <Box
          aria-owns={open ? "selectText" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => {
            setInfo(e.currentTarget);
          }}
          onMouseLeave={() => {
            setInfo(null);
          }}
        >
          <a
            href="https://www.focusmonk.co/tutorial"
            target="_blank"
            className="text-2xl"
          >
            <GrCircleInformation className="text-2xl" />
          </a>
        </Box>
        <Popover
          id="selectText"
          sx={{
            pointerEvents: "none",
          }}
          open={isopen}
          anchorEl={info}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => {
            setInfo(null);
          }}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, background: "#000", color: "#fff" }}>
            Go to complete tutorial!
          </Typography>
        </Popover>
      </Box>

      <NotificationSection />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
