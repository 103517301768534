import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));

//Stripe
const PaymentSuccess = Loadable(lazy(() => import('views/pages/stripe/PaymentSuccess')));
const PaymentChecking = Loadable(lazy(() => import('views/pages/stripe/CheckingPayment')));
// Blocked Page
const Blocked = Loadable(lazy(() => import('views/pages/BlockedPage/BlockedPage')));
const AppGAuth = Loadable(lazy(() => import('views/pages/appCallback/AppCallback')));



// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  // path: '/',
  // element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin3 />,
    },
    {
      path: "/register",
      element: <AuthRegister3 />,
    },
      {
      path: "/register/company",
      element: <AuthRegister3 />,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />,
    },
    {
      path: "/resetpassword",
      element: <ResetPassword />,
    },
    {
      path: "/payment-successful",
      element: <PaymentSuccess />,
    },
    {
      path: "/payment-checking",
      element: <PaymentChecking />,
    },
    {
      path: "/blocked",
      element: <Blocked />,
    },
    {
      path: "/focus/auth/app",
      element: <AppGAuth />,
    },
  ],
};

export default AuthenticationRoutes;
